import type {
  Address,
  DeliveryArea,
  DineInPoint,
  OrderDate,
  OrderTime,
  OrderType,
  Payment,
  PickupPoint
} from '@/types/order'
import { StorageSerializers, useStorage } from '@vueuse/core'

export const useOrderStore = defineStore('order', () => {
  const api = useApi()
  const { priceFormat } = useCurrency()
  const appStore = useAppStore()
  const cartStore = useCartStore()
  const userStore = useUserStore()

  const orderTypes = ref<OrderType[]>([
    // { code: 'delivery', label: 'Доставка' },
    // { code: 'pickup', label: 'Самовывоз' },
    // { code: 'dinein', label: 'В заведении' },
  ])

  async function fetchOrderTypes() {
    orderTypes.value = await api.getOrderTypes()

    if (
      selectedOrderType.value &&
      !orderTypes.value.some((type) => type.code === selectedOrderType.value?.code)
    ) {
      selectedOrderType.value = null
    }
  }

  const modalOrderTypeVisible = ref(false)
  const modalAddressVisible = ref(false)
  const modalPickupPointVisible = ref(false)
  const modalDineInVisible = ref(false)

  const selectedOrderType = useStorage<OrderType | null>('selectedOrderType', null, localStorage, {
    serializer: StorageSerializers.object
  })

  const selectedPickupPoint = useStorage<PickupPoint | null>(
    'selectedPickupPoint',
    null,
    localStorage,
    { serializer: StorageSerializers.object }
  )

  const selectedDineInPoint = useStorage<DineInPoint | null>(
    'selectedDineInPoint',
    null,
    localStorage,
    { serializer: StorageSerializers.object }
  )

  const addressList = ref<Address[] | null>(null)
  const selectedAddressId = useStorage<Address['id'] | null>('selectedAddressId', null)
  const selectedAddress = computed<Address | null>(() => {
    return addressList.value?.find((address) => address.id === selectedAddressId.value) || null
  })

  const pickupPoints = ref<PickupPoint[]>([])

  const dineInPoints = computed<DineInPoint[]>(() => pickupPoints.value.filter(isDineInPoint))

  const isDineInPoint = (point: PickupPoint): point is DineInPoint => {
    return point.isDineIn === true
  }

  async function fetchPickupPoints() {
    pickupPoints.value = await api.getPickupPoints()

    if (
      selectedPickupPoint.value &&
      !pickupPoints.value.some((point) => point.id === selectedPickupPoint.value?.id)
    ) {
      selectedPickupPoint.value = null
    }
  }

  async function fetchAddressList() {
    addressList.value = await api.getAddressList()
  }

  const errorMessage = computed<string | null>(() => {
    if (!selectedOrderType.value || (isPickup.value && !selectedPickupPoint.value)) {
      return 'Выберите тип заказа'
    }

    if (isDelivery.value) {
      if (!selectedAddress.value) {
        return 'Укажите адрес доставки'
      }

      if (!selectedDeliveryArea.value) {
        return 'Доставка по выбранному адресу не осуществляется'
      }

      const minOrder = selectedDeliveryArea.value?.minOrder
      const cartTotal = cartStore.cart?.total || 0

      if (minOrder && minOrder > cartTotal) {
        return `Минимальная сумма заказа ${priceFormat(minOrder)}`
      }
    }

    if (!cartStore.cart?.items?.length) {
      return 'Ваша корзина пуста'
    }

    const cartErrors = cartStore.cart?.errors

    if (cartErrors?.length) {
      return cartErrors.join(', ')
    }

    return null
  })

  const isFreeDelivery = computed<boolean>(() => {
    if (!selectedDeliveryArea.value?.deliveryCost) return true

    const { freeDelivery } = selectedDeliveryArea.value
    const subTotal = cartStore.cart?.subTotal || 0

    return freeDelivery && freeDelivery <= subTotal ? true : false
  })

  const selectedDeliveryArea = ref<DeliveryArea | null>(null)

  watch(
    () => selectedAddress.value?.id,
    () => {
      if (useAppStore().appReady) {
        fetchDeliveryArea()
      }
    }
  )

  async function fetchDeliveryArea() {
    const address = selectedAddress.value

    if (!address?.addressLine) {
      selectedDeliveryArea.value = null
      return
    }

    try {
      selectedDeliveryArea.value = await api.getDeliveryArea(address.addressLine)
    } catch {
      selectedDeliveryArea.value = null
    }
  }

  const isDelivery = computed(() => selectedOrderType.value?.code === 'delivery')
  const isPickup = computed(() => selectedOrderType.value?.code === 'pickup')
  const isDineIn = computed(() => selectedOrderType.value?.code === 'dinein')

  const comment = ref('')
  const personsEnabled = ref(false)
  const persons = ref(1)
  const tableNumber = ref()
  const callMe = ref(false)

  async function createOrder() {
    if (!selectedOrderType.value) throw new Error('Не выбран тип заказа')
    if (!userStore.user) throw new Error('Пользователь не найден')
    if (!asap.value && !selectedDate.value) throw new Error('Пожалуйста, укажите дату заказа')
    if (!asap.value && !selectedTime.value) throw new Error('Пожалуйста, укажите время заказа')
    if (!selectedPayment.value) throw new Error('Пожалуйста, выберите способ оплаты')

    const result = await api.createOrder(
      {
        type: selectedOrderType.value,
        contactName: userStore.user.name,
        contactPhone: userStore.user.phoneNumber,
        persons: (personsEnabled.value && persons.value) || undefined,
        comment: comment.value,
        address:
          selectedOrderType.value.code === 'delivery' && selectedAddress.value
            ? selectedAddress.value
            : undefined,
        pickupPoint:
          selectedOrderType.value.code === 'pickup' && selectedPickupPoint.value
            ? selectedPickupPoint.value
            : undefined,
        dineInPoint:
          selectedOrderType.value.code === 'dinein' && selectedDineInPoint.value
            ? selectedDineInPoint.value
            : undefined,
        payment: selectedPayment.value,
        asap: asap.value,
        date: selectedDate.value?.value,
        time: selectedTime.value?.value,
        changeCash: selectedPayment.value.needChangeCash ? changeCash.value : undefined,
        tableNumber: isDineIn.value ? tableNumber.value : undefined,
        callMe: callMe.value
      },
      {
        ...useUtmStore().$state
      }
    )

    return result
  }

  const paymentList = ref<Payment[]>([])

  const selectedPayment = ref<Payment | null>(null)

  async function fetchPaymentList() {
    paymentList.value = await api.getPaymentList(selectedOrderType.value?.code)
  }

  const asap = ref(true)

  const asapDisabled = computed<boolean>(() => {
    return Boolean(
      appStore.settings.merchantStatuses?.nonWorkingTime.active ||
        appStore.settings.merchantStatuses?.busy.active
    )
  })

  const selectedDate = ref<OrderDate>()
  const selectedTime = ref<OrderTime>()

  const changeCash = ref<string>()

  return {
    orderTypes,
    selectedOrderType,
    selectedPickupPoint,
    selectedDineInPoint,
    selectedAddress,
    addressList,
    selectedAddressId,
    modalOrderTypeVisible,
    modalPickupPointVisible,
    modalAddressVisible,
    modalDineInVisible,
    pickupPoints,
    dineInPoints,
    fetchPickupPoints,
    fetchAddressList,
    isDineInPoint,
    errorMessage,
    selectedDeliveryArea,
    isFreeDelivery,
    isDelivery,
    isPickup,
    isDineIn,
    comment,
    personsEnabled,
    persons,
    fetchDeliveryArea,
    createOrder,
    paymentList,
    fetchPaymentList,
    selectedPayment,
    asap,
    asapDisabled,
    selectedDate,
    selectedTime,
    fetchOrderTypes,
    changeCash,
    tableNumber,
    callMe
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOrderStore, import.meta.hot))
}
